import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class WebsiteLayoutSevice {


    paginate( index = null) {
        let url = API_URL + 'user/template-design-block';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }
    filter( key = null) {
        let url = API_URL + 'user/template-design-block';
        if (key != null)
            url = url + '?type=' + key
        return apiService.get(url);
    }

    create(slug, data) {
        let url = API_URL + 'user/template-design-block'
        return apiService.post(url, data);
    }

    update(slug, id, data) {
        let url = API_URL + 'user/template-design-block/' + id
        return apiService.put(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/template-design-block/' + id
        return apiService.delete(url);
    }
    show(slug, id) {
        let url = API_URL + 'user/template-design-block/' + id
        return apiService.get(url);
    }


}
