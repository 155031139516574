<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <h3>Layout Settings</h3>
                <v-btn :to="{ name: 'manage-websites', params: { domainname: siteUrl }}" class="btn btn-lg btn-secondary-main" outlined
                       style="background:transparent;" text>
                    <i class="fas fa-arrow-left"></i> Back
                </v-btn>
                <span class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right">
        <i class="fa fa-globe"></i>
        {{ siteUrl }}

    </span>
            </div>
        </div>

        <v-app>
            <div class="row" v-if="loading">
                <div class="col-md-12 mt-2">
                    <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
                    <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
                    <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
                </div>
            </div>
            <div class="mt-5" v-else-if="!loading">
                <v-tabs @change="getSelectedValue" class="custom__vuetify__tab" v-model="type"  center-active align-with-title height="50" background-color="#f3f6f9" color="green">
                    <v-tab :key="index" v-for="(layout,index) in types " class="mx-2" :transition="false" style="background-color: white;">
                        {{ layout.text }}
                    </v-tab>

                    <v-tab-item :transition="false" :key="index" v-for="(layout,index) in types "  >
                        <v-col cols="12">

                            <v-row>
                                <v-col cols="6" class="pull-right text-right headline">
                                    {{ layout.text }}
                                </v-col>
                                <v-col class="pull-right p-4" cols="6">

                                    <v-btn :disabled="selectedLayout.id ? false : true" @click="update"
                                           class="btn btn-primary btn-sticky text-white pull-right" color="white--text ">save
                                    </v-btn>
                                </v-col>
                                <div v-if="layouts.length>0">
                                    <v-radio-group class="layout-selector" row v-model="selectedLayout">
                                        <div :key="i" class="col-md-6" v-for="(layout,i) in layouts">

                                            <v-radio :value="layout" width="100%" color="red"  :active-class="'border-selected'"   >

                                                <template v-slot:label>

                                                    <v-card :class="sortIconClass(layout.id, selected_id)" class="col-md-12 "
                                                            outlined>
                                                        <v-card-title>
                                                            <div class="col-md-6">
                                                                    <span :class="layout.id === selected_id ? 'text-success': 'text-primary' ">{{ layout.title }}</span>
                                                                <span class="btn btn-success ml-4"
                                                                      v-if="layout.id === selected_id">Active</span>
                                                            </div>
                                                            <div class="col-md-6 text-right">
                                                                <span @click="imagePreview(layout.main_image_path['real'])"
                                                                      class="btn btn-warning"><i
                                                                        class="fa fa-eye"></i> </span>
                                                            </div>
                                                        </v-card-title>
                                                        <progressive-img
                                                                :src="layout.thumbnail_path['real']"></progressive-img>
                                                    </v-card>
                                                </template>
                                            </v-radio>
                                        </div>
                                        <v-col class="pull-right p-4" cols="12">
                                            <v-btn :disabled="selectedLayout.id ? false : true" @click="update"
                                                   class="btn btn-primary text-white pull-right" color="white--text ">save
                                            </v-btn>
                                        </v-col>
                                    </v-radio-group>


                                </div>
                                <v-col cols="12" md="12" sm="12" v-else>
                                    <span>No layout available</span>
                                </v-col>
                                <v-dialog
                                        scrollable
                                        transition="dialog-bottom-transition" v-model="dialog"
                                >
                                    <v-card>
                                        <v-card-text>
                                            <div class="text-h2 pa-12">
                                                <progressive-img :src="preview_image_path"></progressive-img>
                                            </div>
                                        </v-card-text>
                                        <v-card-actions class="justify-end">
                                            <v-btn
                                                    @click="dialog = false"
                                                    text
                                            >Close
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-row>

                        </v-col>
                    </v-tab-item>

                </v-tabs>
            </div>
        </v-app>
    </div>
</template>

<script>
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import Websitelayout from "@/services/Websites/WebsiteLayoutService";
    import WebsiteSetting from "@/services/Websites/WebsiteSettingService";
    import Website from "@/services/Websites/WebsiteService";

    const WebsiteLayoutService = new Websitelayout();

    const WebsiteSettingService = new WebsiteSetting();

    const WebsiteService = new Website();
    export default {
        name: "layouts-settings",
        data() {
            return {
                type: 0,
                loading: true,
                preview_image_path: '',
                dialog: false,
                types: [{
                    value: "header",
                    text: "Header Layout"
                },
                    {
                        value: "footer",
                        text: "Footer Layout"
                    },
                    {
                        value: "blog_main",
                        text: "Blog Category Page"
                    },
                    {
                        value: "blog_single",
                        text: "Blog Single Post "
                    },
                    {
                        value: "catalog",
                        text: "Catalog Page "
                    },
                    {
                        value: "product_layout",
                        text: "Product Page"
                    },
                    {
                        value: "product_single",
                        text: "Product Single Page "
                    },
                    {
                        value: "login",
                        text: "Login Page "
                    },
                    {
                        value: "registration",
                        text: "Registration Page "
                    },
                    {
                        value: "thank_you",
                        text: "Thank You Page "
                    },
                    {
                        value: "cart",
                        text: "Cart Page"
                    }
                ],
                pageSetting: {
                    id: null,
                    header_design_id: null,
                    footer_design_id: null,
                    cart_page_design_id: null,
                    shop_main_design_id: null,
                    thank_you_page_design_id: null,
                    checkout_page_design_id: null,
                    blog_main_design_id: null,
                    blog_single_design_id: null,
                    login_design_id: null,
                    registration_design_id: null,
                    product_single_design_id: null,
                    product_layout_design_id: null,
                },
                selected_id: 0,
                selectedLayout: {
                    id: null,
                    title: "",
                    path: "",
                    thumbnail_path: [],
                    type: "",
                    is_active: 1
                },
                layouts: [],
                website: ''
            };
        },
        watch: {
            type(newValue, oldValue) {
                this.changeType();
            }
        },
        components: {
            KTPortlet
        },
        methods: {
            getLayouts() {
                WebsiteLayoutService.paginate().then(res => {
                    this.layouts = res.data.data;
                    this.loading = false;
                });
            },
            reset() {
                this.layouts = [];
                this.selectedLayout = {
                    id: null,
                    title: "",
                    path: "",
                    thumbnail_path: [],
                    main_image_path: [],
                    type: "",
                    is_active: 1
                };
            },
            changeType() {
                this.layouts = [];
                WebsiteLayoutService.filter(this.types[this.type].value).then(res => {
                    if (res.data.data.length > 0) {
                        this.layouts = res.data.data;
                    } else {
                        this.reset();
                    }
                });
            },
            getSettings() {
                WebsiteSettingService.paginate(this.siteUrl).then(res => {
                    this.pageSetting = res.data;
                    this.getSelectedValue();
                    this.loading = false;
                });
            },
            update() {
                switch (this.types[this.type].value) {
                    case "header":
                        this.pageSetting.header_design_id = this.selectedLayout.id;
                        break;
                    case "footer":
                        this.pageSetting.footer_design_id = this.selectedLayout.id;
                        break;
                    case "blog_main":
                        this.pageSetting.blog_main_design_id = this.selectedLayout.id;
                        break;
                    case "blog_single":
                        this.pageSetting.blog_single_design_id = this.selectedLayout.id;
                        break;
                    case "catalog":
                        this.pageSetting.shop_main_design_id = this.selectedLayout.id;
                        break;
                    case "checkout":
                        this.pageSetting.checkout_page_design_id = this.selectedLayout.id;
                        break;
                    case "default_template":
                        this.pageSetting.template = this.selectedLayout.id;
                        break;
                    case "login":
                        this.pageSetting.login_design_id = this.selectedLayout.id;
                        break;
                    case "product_layout":
                        this.pageSetting.product_layout_design_id = this.selectedLayout.id;
                        break;
                    case "product_single":
                        this.pageSetting.product_single_design_id = this.selectedLayout.id;
                        break;
                    case "registration":
                        this.pageSetting.registration_design_id = this.selectedLayout.id;
                        break;
                    case "thank_you":
                        this.pageSetting.thank_you_page_design_id = this.selectedLayout.id;
                        break;
                    case "cart":
                        this.pageSetting.cart_page_design_id = this.selectedLayout.id;
                        break;
                }
                this.pageSetting.type = this.type.value

                this.updateSetting();
            },
            getSelectedValue() {
                switch (this.types[this.type].value) {
                    case "header":
                        this.selected_id = this.pageSetting.header_design_id;
                        break;
                    case "footer":
                        this.selected_id = this.pageSetting.footer_design_id;
                        break;
                    case "blog_category":
                        this.selected_id = this.pageSetting.blog_main_design_id;
                        break;
                    case "blog_single":
                        this.selected_id = this.pageSetting.blog_single_design_id;
                        break;
                    case "catalog":
                        this.selected_id = this.pageSetting.shop_main_design_id;
                        break;
                    case "checkout":
                        this.selected_id = this.pageSetting.checkout_page_design_id;
                        break;
                    case "default_template":
                        this.selected_id = this.pageSetting.template;
                        break;
                    case "login":
                        this.selected_id = this.pageSetting.login_design_id;
                        break;
                    case "product_layout":
                        this.selected_id = this.pageSetting.product_layout_design_id;
                        break;
                    case "product_single":
                        this.selected_id = this.pageSetting.product_single_design_id;
                        break;
                    case "registration":
                        this.selected_id = this.pageSetting.registration_design_id;
                        break;
                    case "thank_you":
                        this.selected_id = this.pageSetting.thank_you_page_design_id;
                        break;
                    case "cart":
                        this.selected_id = this.pageSetting.cart_page_design_id;
                        break;
                }
            },

            updateSetting() {
                WebsiteSettingService.updateLayout(
                    this.siteUrl,
                    this.pageSetting.id,
                    this.pageSetting
                ).then(res => {
                    this.$snotify.success("Settings  Updated");
                    this.initSettings();
                });
            },
            initSettings() {
                // this.getLayouts();
                this.getSettings();
            },

            getWebsite() {

                WebsiteService.get(
                    this.siteUrl
                ).then(res => {
                    this.website = res.data.website;
                    this.loading = false;

                });
            },
            imagePreview(path) {
                this.dialog = true;
                this.preview_image_path = path;
            },
            sortIconClass(id, selectedId) {
                return (id === selectedId) ? 'selected-layout' : 'not-selected-layout'
            }
        },
        computed: {
            siteUrl() {
                return this.$route.params.domainname;
            },
            selectedType() {
                return this.types[this.type].value;
            }
        },
        mounted() {
            this.initSettings();
            this.getWebsite();
            this.changeType();
        }
    };
</script>

<style scoped>
    .btn-sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 80px;
        right: 0;
    }
    .v-window-item.v-window-item--active, .v-window-item.v-window-item--active .v-application, .v-window-item.v-window-item--active .card {
        background: #f3f6f9 !important;
    }
    .border-selected{
        border-radius: 5px;
        border: 2px solid #3498db !important;
        overflow: hidden;
    }

    .v-input--selection-controls__input{

        display: none !important;
    }
</style>
<style lang="scss">
    .layout-selector .v-input--selection-controls__input {
        width: 0;
        padding: 0;
        height: 0;
        margin-right: 0 !important;
        opacity: 0;
        visibility: hidden;
    }
    .custom__vuetify__tab {
        .v-slide-group__prev, .v-slide-group__next {
            display: none;
        }

        & > div  {
            height: auto !important;
        }

        .v-tabs-slider-wrapper {
            bottom: auto;
            display: none;
        }

        .v-tab {
            height: 40px;
            margin-bottom: 10px;
        }

        .v-slide-group__wrapper {
            overflow: visible !important;

            .v-tabs-bar__content {
                flex-wrap: wrap;
                flex: auto !important;
            }
        }
    }
</style>
