import { render, staticRenderFns } from "./LayoutsSetting.vue?vue&type=template&id=ff0ae39c&scoped=true&"
import script from "./LayoutsSetting.vue?vue&type=script&lang=js&"
export * from "./LayoutsSetting.vue?vue&type=script&lang=js&"
import style0 from "./LayoutsSetting.vue?vue&type=style&index=0&id=ff0ae39c&scoped=true&lang=css&"
import style1 from "./LayoutsSetting.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff0ae39c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VApp,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VDialog,VRadio,VRadioGroup,VRow,VSkeletonLoader,VTab,VTabItem,VTabs})
